.navigation-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 1);
}
.navigation-link {
  font-weight: bold;
  display: inline;
  color: #5f5f5f;
  text-decoration: none;
  font-size: 40px;
}
.navigation-container {
}
.mobile-navigation {
  display: inline;
}
.desktop-navigation {
  display: none;
}
.hamburger-container {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 1;
  -webkit-trasform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #5f5f5f;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 7px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 17px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 27px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
}
.hamburger-container.open {
  span {
    color: #0a0a0a;
    &:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 7px;
      left: 0px;
    }
    &:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    &:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 27px;
      left: 0px;
    }
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 768px) {
  .mobile-navigation {
    display: none;
  }
  .desktop-navigation {
    display: inline;
  }
  .navigation-link {
    display: inline-block;
    transition: 500ms;
    margin-right: 30px;
    font-size: 16px;
    color: #5f5f5f;
    transition: 500ms;
    cursor: pointer;
    font-weight: 700;
    &:last-child {
      margin-right: 0px;
    }
    &:hover {
      color: #0a0a0a;
    }

    &::after {
      content: "";
      width: 0px;
      height: 2px;
      display: block;
      background: #5f5f5f;
      transition: 500ms;
    }

    &:hover::after {
      width: 100%;
      color: #0a0a0a;
    }
    &--selected {
    }
  }
}
