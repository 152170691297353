.landscape-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 25px;
  padding-left: 5vw;
  padding-right: 5vw;
}

.landscape-main {
  padding: 2rem;
}

.landscape-container {
  position: relative;
  //width: 90vw;
  //width: 100%;
  overflow: hidden;
  cursor: pointer;
  margin: 0 1rem;
  //height: 100%;
}
.landscape-image {
  object-fit: cover;
  width: auto;
  height: 130px;
  transform: scale(1);
  transition: 500ms;
  transition-delay: 250ms;
}
.landscape-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 500ms;
  transition-delay: 250ms;
  color: white;
  font-size: 6vw;
  z-index: 1;
  overflow: hidden;
  p {
    text-align: center;
  }
  &:hover {
    opacity: 1;
    font-size: 5vw;
    + .landscape-image {
      transform: scale(1.4);
      transform-origin: center;
    }
  }
}

@media (min-width: 480px) {
  .landscape-image {
    width: auto;
    height: 200px;
  }
}

@media (min-width: 768px) {

  .landscape-image {
    width: auto;
    height: 250px;
  }

  .landscape-main {
    padding: 2rem;
  }

  .landscape-container {
    //width: calc(45vw - 20px);
  }
  .landscape-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }
}
@media (min-width: 1024px) {
  .landscape-main {
    padding: 4rem;
  }

  .landscape-image {
    width: auto;
    height: 300px;
  }

  .landscape-container {
    //width: calc(45vw - 27.5px);
  }
  .landscape-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
  .landscape-overlay {
    font-size: 72px;
    &:hover {
      font-size: 64px;
    }
  }
}

.arrow {
  &:before {
    color: black;
  }
}
