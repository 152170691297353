.footer-container {
  display: flex;
  justify-content: center;
  color: #5f5f5f;
  align-items: center;
  position: relative;
  bottom: 0px;
  width: 100vw;
  height: 100px;
}
.link-container {
  text-decoration: none;
  color: #7e191b;
  transition: 500ms;

  &:hover {
    color: #0a0a0a;
  }
}
@media (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
