.video-container {
  width: 100vw;
  padding-left: 25%;
}
.player-container {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
.desktop-player {
  position: absolute;
  top: 0;
  left: 0;
}
.mobile-player {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
.desktop-title {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -25vw;
  width: 50vw;
  font-size: 24px;
  font-weight: bold;
}
.mobile-title {
  display: none;
  justify-content: space-between;
  position: relative;
  top: 0vw;
  width: 90vw;
  padding-left: 5vw;
}
.video-details {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  top: -20vw;
  white-space: pre-wrap;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
}
@media (max-width: 768px) {
  .video-container {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .desktop-title {
    display: none;
  }
  .mobile-title {
    display: flex;
  }
  .desktop-player {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .mobile-player {
    display: initial;
    position: absolute;
    top: 0;
    left: 0;
  }
  .video-details {
    top: 0vw;
  }
}
@media (max-width: 1024px) {
}
