.fof-container {
  display: table;
  width: 100%;
  height: calc(100vh - 200px);
  text-align: center;
  color: #0a0a0a;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #5f5f5f;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
